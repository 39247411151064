import { wait } from "@testing-library/user-event/dist/utils";
import ENDPOINTS from "../constants/endpoints"
import { returnWithheaders } from "../utils/utils"

const createSeries = async (data) => {
    const headers = await returnWithheaders();
    console.log(data)
    const resp = await fetch(ENDPOINTS.SERIES_LIST_CREATE, {
        method:"POST",
        body:JSON.stringify(data),
        headers:headers
    })
    return resp;
}

const listSeries = async () => {
    const headers = await returnWithheaders();
    const resp = await fetch(ENDPOINTS.SERIES_LIST_CREATE, {
        method:"GET",
        headers:headers
    })
    return resp;
}


const getLatestSeries = async (SERIES) => {
    const headers = await returnWithheaders();
    const resp = await fetch(
        ENDPOINTS.SERIES_GET_LATEST(SERIES),
        {
            method: "GET",
            headers: headers
        }
    )
    const status = await resp.status
    console.log("console code")
    console.log(status)
    return resp;
}

export {createSeries, listSeries, getLatestSeries}