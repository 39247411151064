import React, { useEffect } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleVerfication } from "../apis/auth";

const EmailVerificationSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get('invitation');

    useEffect(()=>{
        handleVerfication(invitation).then(
        (sCode)=>{if(sCode===201){
            navigate("/create")
        }}
        )
    },[]); 

  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-purple-500 to-indigo-600 text-white">
      <div className="text-center p-8 bg-white bg-opacity-20 rounded-lg shadow-lg w-[90%] max-w-md">
        <CheckCircleOutlined style={{ fontSize: "48px", color: "white" }} />
        <h1 className="text-3xl font-bold mt-4">Email Verified Successfully!</h1>
        <h2 className="text-lg mt-2">
          Thank you for verifying your email address.
        </h2>
        <p className="mt-4">
          Your account is now fully activated, and you can start using all the features.
        </p>
        <div className="mt-6 flex flex-col space-y-4">
          <button
            onClick={() => navigate("/dashboard")}
            className="bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-lg text-lg font-semibold"
          >
            Go to Dashboard
          </button>
          <button
            onClick={() => navigate("/")}
            className="bg-white text-purple-700 hover:bg-purple-700 hover:text-white border border-purple-700 py-2 px-4 rounded-lg text-lg font-semibold"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccessPage;
