import ENDPOINT from "../constants//endpoints";
const getIsAuthenticated = () => {
  if (localStorage.getItem("access") !== "") {
    const resp = fetch(ENDPOINT.VERSION, {
      method: "GET",
      headers: returnWithheaders(),
    });
    if (resp.statusCode === 200) {
      return true;
    }
  }
  return false;
};


const returnWithheaders = async() => {
  const token = await localStorage.getItem("access");
  return {
    Authorization: `Token ${token}`,
    "Content-Type": "application/json",
  };
};

export { getIsAuthenticated, returnWithheaders };
