import React from "react";
import { MailOutlined } from "@ant-design/icons";
import {  useNavigate, useSearchParams } from "react-router-dom";

const EmailVerificationPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")
  const navigate = useNavigate()
  const returnToSiteHandler = () => {
    navigate('/')
  }
  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-purple-500 to-indigo-600 text-white">
      <div className="text-center p-8 bg-white bg-opacity-20 rounded-lg shadow-lg w-[90%] max-w-md">
        <MailOutlined style={{ fontSize: "48px", color: "white" }} />
        <h1 className="text-3xl font-bold mt-4">Verify your email address</h1>
        <h2 className="text-lg mt-2">
          We have sent a verification link to {email}
        </h2>
        <p className="mt-4">
          Click on the link to complete the verification process.
        </p>
        <p className="mt-2 text-sm">
          You might need to check your spam folder.
        </p>
        <div className="mt-6 flex flex-col space-y-4">
          {/* <button className="bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-lg text-lg font-semibold">
            Resend Email
          </button> */}
          <button onClick={returnToSiteHandler} className="bg-white text-purple-700 hover:bg-purple-700 hover:text-white border border-purple-700 py-2 px-4 rounded-lg text-lg font-semibold">
            Return to site
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
