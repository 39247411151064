import React from 'react';

const GradientButton = ({
  onClick,
  text = "Button",
  gradientColors = ['#8e2de2', '#4a00e0'],
  textColor = 'text-white',
  bgAnimation = 'animate-pulse',
  className = '',
  loading = false,
  ...props
}) => {
  return (
    <button
      className={`relative mt-8 ${textColor} text-base md:text-sm px-4 py-2 md:px-6 md:py-3 font-bold rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 overflow-hidden ${className}`}
      style={{
        background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      onClick={onClick}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <span className="relative z-10 flex justify-center items-center">
          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
        </span>
      ) : (
        <span className="relative z-10">{text}</span>
      )}
      <div className={`absolute inset-0 ${bgAnimation} opacity-30`} 
           style={{
             background: `linear-gradient(135deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 100%)`,
           }}>
      </div>
    </button>
  );
};

export default GradientButton;
