import ENDPOINTS from "../constants/endpoints";
import { returnWithheaders} from '../utils/utils';

const handleLogin = async (email, password) => {
  try {
    const response = await fetch(ENDPOINTS.LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username_or_email: email, password }),
    });

    const data = await response.json();

    // Assuming the API returns a token upon successful login
    if (response.ok) {
      // Save the token to local storage or state, depending on your app's architecture
      // For example: localStorage.setItem('token', data.token);
      console.log("Login successful!");
      localStorage.setItem("access", data["token"]);
      // localStorage.setItem("refresh",data['refresh']);
      return true;
    } else {
      console.log("Login failed. Please check your email and password.");
    }
  } catch (error) {
    console.error("Error occurred during login:", error);
    return false;
  }
};

const handleRegister = async (username, email, password) => {
  try {
    const response = await fetch(ENDPOINTS.REGISTER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, email, password }),
    });

    const data = await response.json();

    // Assuming the API returns a token upon successful login
    if (response.ok) {
      // Save the token to local storage or state, depending on your app's architecture
      // For example: localStorage.setItem('token', data.token);
      return response
    } else {
      console.log("Login failed. Please check your email and password.");
    }
  } catch (error) {
    console.error("Error occurred during login:", error);
  }
};

const handleVerfication = async (invitation) => {
  try {
    const response = await fetch(ENDPOINTS.VERIFY_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ invitation }),
    });

    const data = await response.json();
    const sCode = await response.status
    // Assuming the API returns a token upon successful login
    if (sCode===201) {
      // Save the token to local storage or state, depending on your app's architecture
      // For example: localStorage.setItem('token', data.token);
      console.log(data);
      console.log("Login successful!");
      localStorage.setItem("access", data["token"]);
    } else {
      console.log("Login failed. Please check your email and password.");
    }
    return sCode;
  } catch (error) {
    console.error("Error occurred during login:", error);
  }
};

const getMeData = async () => {
  const headers = await returnWithheaders();
  const response = await fetch(ENDPOINTS.ME_URL, {
    method: "GET",
    headers: headers,
  });
  return response;
};

const updateEmail = async (data) => {
  const headers = await returnWithheaders();
  const response = await fetch(ENDPOINTS.ME_URL, {
    method:"POST",
    body:JSON.stringify(data),
    headers:headers,
  })
  return response;

}

export { handleLogin, handleRegister, handleVerfication, getMeData, updateEmail };
