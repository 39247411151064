import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = (props) =>{
    const navigate = useNavigate()
    useEffect(()=>{
        localStorage.removeItem("access");
        navigate("/")
    },[])
    return <div><h1>Loging out!</h1></div>
}

export default Logout;