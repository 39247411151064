import { Button, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useEffect, useState } from "react";
import GradientButton from "../GradientButton";

const PasswordComponent = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        const newPasswordValue = event.target.value;
        setNewPassword(newPasswordValue);

        if (validatePassword(newPasswordValue)) {
            setError('');
        } else {
            setError('Password must be at least 8 characters long and include at least one digit and one special character.');
        }
    };

    return (
        <>
            {contextHolder}
            <h3 className="text-lg font-semibold mb-4">Change Password</h3>
            <div className="w-full max-w-md">
                <Input.Password
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    className="mb-4"
                />
                <Input.Password
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    className="mb-4"
                />
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {/* <Button
  type="default"
  className="bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 text-white font-bold py-2 px-5 rounded-lg shadow-lg flex items-center justify-center transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-purple-300 transition-transform duration-300 ease-in-out border border-transparent"
>
  Update Password
</Button> */}

<GradientButton
  onClick={()=>{}}
  text="Update Password"
  gradientColors = {['#9F7AEA', '#6B46C1']} // Custom gradient colors
  bgAnimation="animate-pulse" // Optional animation class
  textColor="text-white" // Optional text color class
/>

            </div>
        </>
    );
};

const validatePassword = (password) => {
    // Regular expression to validate the password
    // - At least 8 characters
    // - Contains at least one digit
    // - Contains at least one special character
    const re = /^(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
    return re.test(password);
};

export default PasswordComponent;
