import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white text-gray-800 py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-lg font-bold mb-4">Company</h3>
            <p className="mb-4">1234 Street Name, City, State, 12345</p>
            <p className="mb-4">Email: contact@geneshorts.com</p>
            <p>Phone: (123) 456-7890</p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul>
              <li><a href="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
              {/* <li><a href="/blog" className="text-gray-600 hover:text-gray-900">About Us</a></li> */}
              <li><a href="#faqs" className="text-gray-600 hover:text-gray-900">FAQs</a></li>
              <li><a href="/terms-and-conditions" className="text-gray-600 hover:text-gray-900">Terms and conditions</a></li>
              <li><a href="/privacy-policy" className="text-gray-600 hover:text-gray-900">Privacy Policy</a></li>
              {/* <li><a to="/contact" className="text-gray-600 hover:text-gray-900">Contact</a></li> */}
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-lg font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <i className="fab fa-facebook-f fa-lg"></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <i className="fab fa-twitter fa-lg"></i>
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <i className="fab fa-linkedin-in fa-lg"></i>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
                <i className="fab fa-instagram fa-lg"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="text-center mt-8">
          <p className="text-gray-600 text-sm">&copy; {new Date().getFullYear()} Geneshorts. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
