import ENDPOINTS from "../constants/endpoints"
import { returnWithheaders } from "../utils/utils"


const getConntectedChannelsList = async () => {
    const headers = await returnWithheaders();
    const resp = await fetch(
        ENDPOINTS.CHANNELS_LIST_URL,
        {
            "method":"GET",
            headers:headers,
        }
    )
    return resp
}

const deleteChannel = async (cID) => {
    const headers = await returnWithheaders();
    const resp = await fetch(
        ENDPOINTS.CHANNELS_RUD_URL(cID),
        {
            "method":"DELETE",
            headers:headers,
        }
    )
    return resp
}

const initAuth = async (provider) => {
    const headers = await returnWithheaders();
    const response = fetch(ENDPOINTS.CHANNELS_INIT_AUTH(provider), {
        method:"GET",
        headers:headers
    })
    return response

}


export {getConntectedChannelsList, initAuth, deleteChannel}