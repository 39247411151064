import { configureStore } from "@reduxjs/toolkit";
import versionSlice from './version-slice'

const store = configureStore({
  reducer: {
    versionSlice: versionSlice.reducer
  },
});

export default store;
