import React, { useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, GoogleCircleFilled } from '@ant-design/icons';
import { handleRegister } from '../apis/auth';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [agreeToTerms, setAgreeToTerms] = useState(false); // State to track checkbox

  const onFinish = (values) => {
    console.log('Received values:', values);
    handleRegister(values.username, values.email, values.password).then((resp) => {
      console.log(resp.status);
      console.log("redirecting");
      if (resp.status === 201) {
        navigate(`/verify?email=${values.email}`);
      }
    });
  };

  const responseGoogle = (response) => {
    console.log('Google sign-up response:', response);
  };

  const handleCheckboxChange = (e) => {
    setAgreeToTerms(e.target.checked);
  };

  return (
    <Form name="register-form" initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
        <Input prefix={<UserOutlined />} placeholder="Username" />
      </Form.Item>

      <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Please input a valid email address!' }]}>
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>

      <Form.Item name="agreeToTerms" valuePropName="checked" rules={[{ required: true, message: 'You must agree to the terms and conditions!' }]}>
        <Checkbox checked={agreeToTerms} onChange={handleCheckboxChange}>
          I agree to the <a href="/terms">Terms and Conditions</a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block disabled={!agreeToTerms}>
          Register
        </Button>
      </Form.Item>

      <Form.Item>
        or Sign-up with Google
        <Button
          icon={<GoogleCircleFilled />}
          onClick={() => {}}
          block
          style={{ marginTop: '8px' }}
        >
          Sign up with Google
        </Button>
      </Form.Item>

      <Form.Item>
        Already have an account? <a href="/login">Sign in</a>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;
