import { useState } from "react";
import { getBillingLink } from "../../apis/billing";
import {
  PlusCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';

const PlanCard = (props) => {
  const [quantity, setQuantity] = useState(1);
  const MAX_VALUE = 10;

  const addPriceHandler = () => {
    if (quantity === MAX_VALUE) {
      return;
    }
    setQuantity(quantity + 1);
  };

  const minusPriceHandler = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const buttonHandler = async () => {
    const resp = await getBillingLink(
      props.data.plan_id,
      quantity,
      "dadfsdfksddj@gmail.com"
    );
    const data = await resp.json();
    console.log(data);
    window.location.href = data.url;
  };

  let button = (
    <button
      className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-semibold py-2 px-4 rounded-lg w-full transition-all duration-200 ease-in-out"
      onClick={buttonHandler}
    >
      {props.is_current ? "Current" : "Buy"}
    </button>
  );

  if (props.isLanding) {
    button = (
      <button
        className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-semibold py-2 px-4 rounded-lg w-full transition-all duration-200 ease-in-out"
        onClick={buttonHandler}
      >
        Try Now
      </button>
    );
  }

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-lg p-6 max-w-xs transition-transform transform hover:scale-105 relative">
      {props.is_current && (
        <span className="bg-green-100 text-green-700 text-xs font-semibold px-2.5 py-0.5 rounded-full absolute top-4 right-4">
          Current Plan
        </span>
      )}
      <h1 className="text-2xl font-bold mb-2 text-gray-800">{props.data.name}</h1>
      <h2 className="text-xl font-semibold mb-4 text-gray-600">${props.data.int_price * quantity}</h2>
      <div className="space-y-2 mb-4">
        {props.data.values_metadata.map((data, idx) => {
          if (data.text === "Series") {
            return (
              <div key={idx} className="flex items-center space-x-2 justify-center">
                <h4
                  className={`text-md ${data.status ? "text-green-600" : "text-red-600"}`}
                >
                  {quantity} {data.text}
                </h4>
                <div className="flex space-x-2 ">
                  <MinusCircleOutlined onClick={minusPriceHandler} className="text-xl cursor-pointer text-gray-500 hover:text-gray-700" />
                  <PlusCircleOutlined onClick={addPriceHandler} className="text-xl cursor-pointer text-gray-500 hover:text-gray-700" />
                </div>
              </div>
            );
          } else {
            return (
              <h4
                key={idx}
                className={`text-md ${data.status ? "text-green-600" : "text-red-600"}`}
              >
                {data.text}
              </h4>
            );
          }
        })}
      </div>
      {button}
    </div>
  );
};

export default PlanCard;
