import { createSlice } from "@reduxjs/toolkit";

const initialState = { transactionId: 0, data: {} };

const versionSlice = createSlice({
  name: "version",
  initialState: initialState,
  reducers: {
    setVersionReducer(state, action) {
      console.log(action);
      console.log(action.payload.data);
      console.log("version from slice");
      state.data = action.payload.data;
      state.transactionId++
      return state;
    },
    incrementTransactionIndex(state) {
      {
        state.transactionId++;
        return state;
      }
    },
  },
});

export const versionActions = versionSlice.actions;

export default versionSlice;
