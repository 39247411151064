import ENDPOINTS from "../constants/endpoints"
import { returnWithheaders } from "../utils/utils"

const fetchVersionData = async () => {
    const headers = await returnWithheaders()
    const resp = await fetch(ENDPOINTS.VERSION, {
        method:"GET",
        headers:headers,
    })
    return resp
}


export {fetchVersionData};