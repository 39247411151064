const BillingSuccess = (props) => {

    return (
        <div>
            <h1>Success</h1>
            <a>Home go to page</a>
        </div>
    )
}


export default BillingSuccess;