import { Card, Image, Tooltip, Avatar, Skeleton, Modal } from "antd";
import { useEffect, useState } from "react";
import { EditFilled, DeleteFilled, WarningFilled} from "@ant-design/icons";
import {getConntectedChannelsList, initAuth, deleteChannel} from '../apis/channels';
import { wait } from "@testing-library/user-event/dist/utils";
const { Meta } = Card;
const { confirm } = Modal;

const SocialMedia = (props) => {
  const [connectAccounts, setConnectAccounts] = useState([]);
  const providerData = {
    "youtube":"https://w7.pngwing.com/pngs/963/811/png-transparent-youtube-logo-youtube-red-logo-computer-icons-youtube-television-angle-rectangle-thumbnail.png",
    "instagram":"https://w7.pngwing.com/pngs/191/478/png-transparent-social-media-facebook-emoji-icon-instagram-icon-instagram-logo-text-rectangle-magenta-thumbnail.png"
  }
  const loadChannels = async () => {
    // Load channels logic here
   const resp = await getConntectedChannelsList();
   const data = await resp.json()
   if (resp.status == 200){
    setConnectAccounts(data)
   }

  };

  useEffect(()=>{
    loadChannels()
  }, [])

  const handleConnect = async (platform) => {
    console.log(`Connecting to ${platform}`);
    // Add logic to connect to the platform
    const resp = await initAuth(platform)
    const data = await resp.json()
    if (resp.status==200){
      window.location.href = data.url;
    }
  };

  const showDeleteConfirm =  (accountId) => {
    confirm({
      title: 'Are you sure delete this account?',
      icon:<WarningFilled />,
      content: 'Series will be stopped posting on this account',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk : async () => {
        console.log('OK');
        console.log(accountId)
        console.log("accountId will be deleted")
        deleteChannel(accountId).then((resp)=>{
          loadChannels()
        })
        
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <div>
      <h1>Click to connect account</h1>
      <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
        <Tooltip title="Click to connect">
          <Card
            onClick={() => handleConnect("youtube")}
            hoverable
            style={{
              alignItems: "center",
              textAlign: "center",
              width: "180px",
              height: "180px",
              border: "1px solid #e8e8e8",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            bodyStyle={{ padding: "0" }}
          >
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <Image
                preview={false}
                src={providerData.youtube}
                style={{
                  width: "50%",
                  height: "auto",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "1.2em",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  display: "none",
                  transition: "display 0.3s ease-in-out",
                }}
                className="connect-overlay"
              >
                Connect
              </div>
              <h1 style={{ margin: "10px 0" }}>YouTube</h1>
            </div>
          </Card>
        </Tooltip>
        <Tooltip title="Click to connect">
          <Card
            onClick={() => handleConnect("instagram")}
            hoverable
            style={{
              alignItems: "center",
              textAlign: "center",
              width: "180px",
              height: "180px",
              border: "1px solid #e8e8e8",
              borderRadius: "10px",
              overflow: "hidden",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            bodyStyle={{ padding: "0" }}
          >
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <Image
                preview={false}
                src={providerData["instagram"]}
                style={{
                  width: "50%",
                  height: "auto",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "1.2em",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  display: "none",
                  transition: "display 0.3s ease-in-out",
                }}
                className="connect-overlay"
              >
                Connect
              </div>
              <h1 style={{ margin: "10px 0" }}>Instagram</h1>
            </div>
          </Card>
        </Tooltip>
      </div>
      <br></br>
      <hr />
      <h1>Connected accounts</h1>
      <div
        style={{
          display: "flex",
          direction: "row",
        }}
      >
        {connectAccounts.map((value, index)=> {
          return <Card
          key={index}
          style={{
            width: 300,
            marginTop: 16,
            margin: 10,
          }}
          actions={[
            // <EditFilled key="edit" />,
            <DeleteFilled key="setting" onClick={()=>{showDeleteConfirm(value.id)}} />,
            //   <EllipsisOutlined key="ellipsis" />,
          ]}
        >
          <Meta
            avatar={
              <Avatar src={providerData[value.provider]} />
            }
            title={value.name}
            // description="Active"
          />
        </Card>
        })}
      </div>
      
    </div>
  );
};

// Add CSS styles directly in the component
const styles = `
.connect-overlay {
    display: none;
}

.ant-card:hover .connect-overlay {
    display: block;
}
`;

// Append the styles to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default SocialMedia;
