import React, { useEffect } from 'react';
import RegistrationForm from './RegisterForm';
import ReactGA from 'react-ga4';

const RegisterPage = () => {
  useEffect(() => {
    // Initialize Google Analytics

    // Track page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700">
      <div className="w-full max-w-md p-6">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-center mb-6">Register</h2>
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
