import { Card, Select, Steps } from "antd";
import { useEffect, useState } from "react";
import { createSeries } from "../apis/series";
import { fetchVersionData } from "../apis/version";
import GradientButton from "../components/GradientButton";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Step } = Steps;

const onSearch = (value) => {
  console.log("search:", value);
};

const filterOption = (input, option) =>
  (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

const Create = (props) => {
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [versionData, setVersionData] = useState({});

  const [account, setAccount] = useState("");
  const [content, setContent] = useState("");
  const [voice, setVoice] = useState("");
  const [lang, setLang] = useState("");
  const [minDuration, setMinDuration] = useState(60);
  const [maxDuration, setMaxDuration] = useState(90);

  const [currentStep, setCurrentStep] = useState(0);

  const handleFetching = () =>{
    fetchVersionData().then((resp) => {
      if (resp.status === 200) {
        resp.json().then((data) => {
          setIsPaid(data.is_paid);
          setVersionData(data)
          setIsLoaded(true)
        });
      }
    });
  }

  const checkAuth = async () => {
    const value = await localStorage.getItem("access");
    if (value===null){
      navigate("/login")
    }
  }

  useEffect(() => {
    checkAuth().then(
      ()=>{handleFetching()}
    )
  }, []);

  const accountHandler = (value) => {
    setAccount(value);
    if (currentStep > 1){
      return
    }
    setCurrentStep(1);
  };

  const contentHandler = (value) => {
    setContent(value);
    if (currentStep > 2){
      return
    }
    setCurrentStep(2);
  };

  const narrationVoiceHandler = (value) => {
    setVoice(value);
    if (currentStep > 3){
      return
    }
    setCurrentStep(3);
  };

  const videoLanguageHandler = (value) => {
    setLang(value);
    if (currentStep > 4){
      return
    }
    setCurrentStep(4);
  };

  const durationHandler = (value) => {
    if (value === "1") {
      setMinDuration(60);
      setMaxDuration(90);
    } else if (value === "2") {
      setMinDuration(30);
      setMaxDuration(60);
    } else if (value === "3") {
      setMinDuration(90);
      setMaxDuration(120);
    }
    setCurrentStep(5);
    if (currentStep > 5){
      return
    }
  };
  console.log(isLoaded)
  console.log(versionData.series_count )
  if(isLoaded && versionData.series_count >= versionData.subscription.license){
   return  <section className="flex justify-center p-6 bg-gradient-to-b from-white to-gray-100 ">
    <Card className="w-full max-w-3xl p-6 bg-white shadow-lg rounded-lg border-0">
      <h1 className="text-3xl font-extrabold text-gray-800 mb-4">Create Series</h1>
    <p className="text-red-600	text-xl	">Please upgrade the plan you've already reached the series limit</p>
      <GradientButton disabled text="Create Series"  className="w-full mt-2">
              Create Series
            </GradientButton>
          
      </Card>
    </section>
  }

  return (
    <section className="flex justify-center p-6 bg-gradient-to-b from-white to-gray-100 ">
      <Card className="w-full max-w-3xl p-6 bg-white shadow-lg rounded-lg border-0">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-4">Create Series</h1>
        
        <Steps
          current={currentStep}
          size="small"
          className="mb-8"
          // progressDot
        >
          <Step title="Account" />
          <Step title="Content" />
          <Step title="Voice" />
          <Step title="Language" />
          <Step title="Duration" />
        </Steps>
        
        <div className="space-y-6">
          {currentStep >= 0 && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-1">Destination</h2>
              <p className="text-sm text-gray-500 mb-1">The account where you will post</p>
              <Select
                showSearch
                placeholder="Select an Account"
                optionFilterProp="children"
                onChange={accountHandler}
                onSearch={onSearch}
                filterOption={filterOption}
                className="w-full rounded-lg"
                // disabled={!isPaid}
              >
                <Option value={1} disabled={!isPaid}>Instagram</Option>
                <Option value={2} disabled={!isPaid}>YouTube</Option>
                <Option value={5} disabled={!isPaid}>Facebook</Option>
                <Option value={4}>Email</Option>
              </Select>
            </div>
          )}

          {currentStep >= 1 && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-1">Content</h2>
              <p className="text-sm text-gray-500 mb-1">What will your video series be about?</p>
              <Select
                showSearch
                placeholder="Select Content"
                optionFilterProp="children"
                onChange={contentHandler}
                onSearch={onSearch}
                filterOption={filterOption}
                className="w-full rounded-lg"
              >
                <Option value="Motivation">Motivation</Option>
                <Option value="Scary">Scary</Option>
                <Option value="History">History</Option>
              </Select>
            </div>
          )}

          {currentStep >= 2 && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-1">Narration Voice</h2>
              <Select
                showSearch
                placeholder="Select a Voice"
                optionFilterProp="children"
                onChange={narrationVoiceHandler}
                onSearch={onSearch}
                filterOption={filterOption}
                className="w-full rounded-lg"
              >
                <Option value="Onyx">Onyx</Option>
                <Option value="Fable">Fable</Option>
                <Option value="Nova">Nova</Option>
              </Select>
            </div>
          )}

          {currentStep >= 3 && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-1">Video Language</h2>
              <Select
                showSearch
                placeholder="Select a Language"
                optionFilterProp="children"
                onChange={videoLanguageHandler}
                onSearch={onSearch}
                filterOption={filterOption}
                className="w-full rounded-lg"
              >
                <Option value="English">English</Option>
                <Option value="Tamil">Tamil</Option>
                <Option value="French">French</Option>
              </Select>
            </div>
          )}

          {currentStep >= 4 && (
            <div>
              <h2 className="text-lg font-semibold text-gray-700 mb-1">Duration Preference</h2>
              <Select
                showSearch
                placeholder="Select a Duration Preference"
                optionFilterProp="children"
                onChange={durationHandler}
                onSearch={onSearch}
                filterOption={filterOption}
                className="w-full rounded-lg"
              >
                <Option value="1">60 to 90 seconds</Option>
                <Option value="2">30 to 60 seconds</Option>
                <Option value="3">90 to 120 seconds</Option>
              </Select>
            </div>
          )}

          {currentStep >= 5 && (
            <GradientButton loading={buttonLoading} onClick={()=>{
              setButtonLoading(true)
              createSeries(  {
                channel:account,
                content:content,
                narration_voice:voice,
                video_language:lang,
                min_duration:minDuration,
                max_duration:maxDuration
              }).then((resp)=>{
                if(resp.status===201){
                  resp.json().then(data =>{
                    navigate(`/series/detail/${data.id}/`)
                  })
                 
                }
              }
            ).then(()=>{setButtonLoading(false)})
            }} className="w-full mt-2">
              Create Series
            </GradientButton>
          )}
        </div>
      </Card>
    </section>
  );
};

export default Create;
