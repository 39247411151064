// Import necessary components and icons
import React from 'react';
import { Layout, Menu } from 'antd';
import ReactGA from "react-ga4";
import { Routes, Route, Link, BrowserRouter as Router, createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './views/RootLayout'; // Import Dashboard component
import Home from './views/Home'
import SignIn from './views/Signin';
import SignUp from './views/Signup';
import Series from './views/Series';
import Create from './views/Create';
import Views from './views/Views';
import Billing from './views/Billing';
import BillingSuccess from './views/BillingSuccess';
import Settings from './views/Settings';
import VerficationEmail from './views/VerificationEmail';
import BillingCancel from './views/BillingCancel';
import LandingPage from './views/LandingPage';
import LoginPage from './views/LoginPage';
import RegisterPage from './views/RegisterPage';
import SocialMedia from './views/SocialMedia';
import DetailSeries from './views/DetailSeries';
import Logout from './views/Logout'
import EmailVerificationPage from './views/EmailVerificationPage';
import EmailVerificationSuccessPage from './views/EmailVerificationSuccessPage';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsAndConditions from './views/terms';

const router = createBrowserRouter([
  {
    path: '/',
   
    
    children: [
      {
        path:"/",
        element:<LandingPage/>
      },
      {
        
        // Dashboard route
        path: '/',
        element: <RootLayout />,
        children:[
         
          {
            path: "/home",
            element: <Home/>
          },
          {
            path: "/series",
            element: <Series/>
          },
          {
            path: "/create",
            element: <Create/>
          },
          {
            path: "/views",
            element: <Views/>
          },
          {
            path: "/channels",
            element: <SocialMedia/>
          },
          {
            path: "/billing",
            element: <Billing/>
          },
          {
            path: "/settings",
            element: <Settings/>
          },
          {
            path: "/billing/success",
            element: <BillingSuccess/>
          },
          {
            path: "/billing/cancel",
            element: <BillingCancel/>
          },
          {
            path:"series/detail/:id",
            element: <DetailSeries/>
          },
          {
            path:"/logout",
            element: <Logout/>
          },
          
    
        ]
      },
      {
        path: "/login",
        element: <LoginPage/>
      },
      {
        path: "/register",
        element: <RegisterPage/>
      },

      {path:"/verify",
        element: <EmailVerificationPage/>
      },
      {
        path:"/verification_successfull",
        element:<EmailVerificationSuccessPage />
      },
      {
        path:'/privacy-policy',
        element:<PrivacyPolicy/>
      },
      {
        path:'/terms-and-conditions',
        element:<TermsAndConditions/>
      }
      
    ],
  },
]);


ReactGA.initialize("G-4YYT5YD6G8");
function App() {
  return <RouterProvider router={router} />;
}

export default App;
