import ENDPOINTS from '../constants/endpoints';
import {getIsAuthenticated, returnWithheaders} from '../utils/utils';

const getBillingPlans = async (is_landing=false) => {
    const headers = await returnWithheaders();
    if(is_landing){
        delete headers['Authorization'];
    }
    const resp = await fetch(ENDPOINTS.BILLING_PLANS, {
        method:"GET",
        headers
    })
    return resp
}


const getBillingLink = async (price_id, quantity) => {
    const headers = await returnWithheaders();
    const resp = await fetch(ENDPOINTS.BILLING_LINK(price_id, quantity, "dadfsdfksddj@gmail.com"), {
        method:"GET",
        headers:headers
    })
    return resp
}



export {getBillingPlans, getBillingLink}