import React, { useState } from "react";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-2xl font-medium">{title}</h3>
        <span className="text-xl">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && (
        <div className="p-4 bg-gray-10">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

const Accordion = () => {
  var data = [
    {
      title: "What is a Series?",
      content: ` A "Series" in GeneShorts.ai is a set of instructions that tells our AI how to create and post videos automatically. It includes your chosen topic, posting schedule, and target social media accounts, allowing the system to produce and share videos without your manual input.

    For example, you could create a series called "Scary Stories" that automatically creates content and posts to TikTok and YouTube every day at 9PM EST.

    Other important things to know:

    - Each series can link to one TikTok and one YouTube channel.
    - You can delete and create a new series to change your topic (up to 10 times per day).`,
    },
    {
      title:"What social media platforms do you support posting to?",
      content:"We currently support posting to YouTube. We are working on adding support for other platforms."
    },
    {
      title:"Are the videos unique?",
      content:"Unlike other services that re-use the same video over and over, we create a new video for each series using generative AI. This means that each video is unique and will not be the same as any other video. Even if two videos have the same topic, the script and images will be completely different due to generative AI."
    },
    {
      title: "Are the videos unique?",
      content:
        "Unlike other services that re-use the same video over and over, we create a new video for each series using generative AI. This means that each video is unique and will not be the same as any other video. Even if two videos have the same topic, the script and images will be completely different due to generative AI.",
    },
    {
      title: "Can I edit the videos?",
      content:
        "Yes, you can edit basic details such as your video's script, title, and background music at anytime before it is scheduled to post.",
    },
    {
      title: "How many videos can I create per day?",
      content:
        "The number of videos created by each series can be seen on our pricing page. Remember, Geneshorts doesn't focus on individual video creation. Instead, you set up a Series that automatically generates videos on a schedule.",
    },
    {
      title: "Can I replace an existing series with a new one?",
      content: `Absolutely! You can delete any current series and start fresh with a new topic or settings anytime (up to 10 times per day). Note: With our free plan, you're able to create one series per account.
`,
    },
    {
      title: "Can I adjust the video length?",
      content: `When creating your series, you can choose between "30 to 60 seconds" or "60 to 90 seconds" length options. For more fine-tuned length control you can manually modify the length of your AI generated script, up to 1,600 characters max.`,
    },
    {
      title: "How do I create a video?",
      content:
        "It's important to know that Geneshorts.ai wasn't designed for single video creation, but instead for creating a series of videos on a regular schedule. First you need to create a series. Once you do, the first video in your series will be automatically be queued for creation.",
    },
    {
      title: "Are there any types of content that are not allowed?",
      content:
        "Our platform features an NSFW filter for our generative AI models. However, we cannot guarantee that all content will be appropriate for all audiences. We do not take responsibility for any content created using our platform.",
    },
    {
      title: "Do I own the videos?",
      content:
        "Yes, the videos are yours to do with as you please. You can download them and use them on other platforms, or even sell them to clients.",
    },
    {
      title: "How can I contact you if I have more questions or need help?",
      content:
        "If you have any questions that are not listed here, feel free to reach out to us at hello@geneshorts.ai. We are here to help and will get back to you as soon as possible.",
    },
    {
      title: "Is there a free trial?",
      content:
        "You bet your sweet bippy there is. Simply create an account and you can create your first series for free to test a video. No credit card required.",
    },
    {
      title:"Can I cancel at anytime?",
      content:"Absolutely. We hate services that purposefully make it difficult to cancel. You can cancel at the click of a button from the dashboard's billing page."
    },
    {
      title:"How does the membership work?",
      content:"Beyond the free plan, we offer different tiers of paid memberships. The paid plans remove the watermark and allow you to post more frequently."
    },
    {
      title:"Can I get a refund?",
      content:"Unfortunately, we cannot offer refunds as costs incurred for creating AI videos and generating AI photos are extremely high. In turn, our upstream providers do not let us ask for refunds for the GPU processing time used to create your AI videos. This would make it a loss making endeavor for us. During sign up you agree to withhold your right to refund for this reason. You can cancel any time though and your subscription ends."
    },
    {
      title:"Can I upgrade or downgrade my subscription?",
      content:"Yes, you can upgrade or downgrade your subscription at any time. Go to the billing tab and select the plan you want to upgrade / downgrade to. If you move to a plan with less series than you currently have, the extra series will be automatically disabled."
    }
  ];
  return (
    <div className="max-w-xxl mx-auto mt-8 mb-1 p-4 bg-white  w-full text-center">
      <h2 className="text-5xl font-bold mb-4 text-center">
        Frequently asked questions
      </h2>

      {data.map((item, idx) => {
        return <AccordionItem title={item.title} content={item.content} />;
      })}
    </div>
  );
};

export default Accordion;
