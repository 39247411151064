import React from 'react';
import { Link } from 'react-router-dom';

const LandingPageFront = (props) => {
  return (
    <>
      {/* Header */}
      <header className="bg-white shadow-md py-4">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-12">
          <div className="flex items-center space-x-6 mb-4 md:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold text-black">Youshorts</h1>
            <nav className="hidden md:flex space-x-6">
              {/* Add navigation links if needed */}
            </nav>
          </div>
          <div className="flex items-center space-x-4">
            <a href='#pricing' className="text-base md:text-lg font-semibold text-black">Pricing</a>
            <a href="/login" className="text-base md:text-lg font-semibold text-black">Login</a>
            <button
              className="bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:from-purple-600 hover:via-purple-700 hover:to-purple-800 text-white text-base md:text-lg font-bold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300"
              style={{ height: 50 }}
            >
              <b>Create</b>
            </button>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-col md:flex-row items-center justify-between min-h-screen px-4 md:px-12 space-y-6 md:space-y-0 md:space-x-8">
        {/* Left Section */}
        <div className="flex-1 text-center md:text-left md:pl-8 flex flex-col justify-center">
          <h1 className="text-4xl md:text-5xl font-extrabold leading-tight text-gray-900">
            Effortless Faceless Video Creation
          </h1>
          <h3 className="text-base md:text-lg font-medium mt-3 text-gray-700">
            Harness the power of our cutting-edge AI platform to fully automate your faceless video production.
          </h3>
          <button
            className="mt-8 bg-gradient-to-r from-purple-400 via-purple-500 to-purple-600 text-white text-base md:text-lg px-4 py-2 md:px-6 md:py-3 font-bold rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 relative overflow-hidden"
            style={{
              background: 'linear-gradient(135deg, #8e2de2 0%, #4a00e0 100%)',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <span className="relative z-10">Get Started Free</span>
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-purple-700 opacity-30 animate-pulse"></div>
          </button>
        </div>

        {/* Right Section with Advantages */}
        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4 p-4 md:p-6">
          <div className="bg-gradient-to-br from-purple-500 to-purple-700 p-6 rounded-lg text-white shadow-md">
            <h3 className="text-xl font-bold mb-2">Anonymity & Privacy</h3>
            <p className="text-sm md:text-base">
              Create content without showing your face, keeping your identity private while reaching a wider audience.
            </p>
          </div>
          <div className="bg-gradient-to-br from-indigo-500 to-indigo-700 p-6 rounded-lg text-white shadow-md">
            <h3 className="text-xl font-bold mb-2">Focus on Content</h3>
            <p className="text-sm md:text-base">
              Concentrate on delivering high-quality content, without worrying about appearances.
            </p>
          </div>
          <div className="bg-gradient-to-br from-blue-500 to-blue-700 p-6 rounded-lg text-white shadow-md">
            <h3 className="text-xl font-bold mb-2">Save Time & Resources</h3>
            <p className="text-sm md:text-base">
              Skip the hassle of setting up a camera or worrying about your surroundings—our platform handles it all.
            </p>
          </div>
          <div className="bg-gradient-to-br from-teal-500 to-teal-700 p-6 rounded-lg text-white shadow-md">
            <h3 className="text-xl font-bold mb-2">Consistency</h3>
            <p className="text-sm md:text-base">
            Maintain a consistent and professional look across all your video content.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageFront;
