import { Card, Button } from "antd";
import PlanCard from '../components/billing/PlanCard';
import { getBillingPlans } from '../apis/billing';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVersionData } from "../apis/version";

const Billing = (props) => {
  const [billingPlans, setBillingPlans] = useState([]);
  const [versionData, setVersionData] = useState();
  const [isLoaded, setIsloaded] = useState(false);
  const dispatch = useDispatch();

  const dataLoader = async () => {
    const resp = await fetchVersionData();
    const data = await resp.json();
    setVersionData(data)
    // dispatch(versionActions.setVersionReducer({ data }));
  };
  const getBillingPlansData = async () => {
    await dataLoader()
    const resp = await getBillingPlans();
    if (resp.status !== 200) {
      // TODO: Show error to the user
    } else {
      console.log("done")
      console.log(versionData);
     resp.json().then(data=>{ setBillingPlans(data);})
     
    }
    setIsloaded(true)
  };

  useEffect(() => {
    console.log("billing is getting called")
    getBillingPlansData();
  }, []);

  if (isLoaded){
    return <div className="p-6 space-y-6">
    {/* Current Plan Card */}
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h1 className="text-2xl font-semibold mb-2">Current Plan</h1>
      <h2 className="text-xl font-medium">{versionData.subscription.plan}</h2>
    </div>

    {/* Change Plans Card */}
    <div className="bg-white shadow-lg rounded-lg p-6">
      <h1 className="text-2xl font-semibold mb-4">Change Plans</h1>
      <div className="flex flex-wrap gap-4">
        {billingPlans.map((data) => (
          <PlanCard
            key={data.plan_id}
            data={data}
            is_current={data.plan_id === versionData.subscription.plan}
          />
        ))}
      </div>
    </div>
  </div>
  }
  else {
    return <div> Loading</div>
  }
};

export default Billing;
