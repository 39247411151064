import React, { useEffect, useState } from "react";
import { Form, Input, Button, Progress } from "antd";
import { useParams } from "react-router-dom";
import { getLatestSeries } from "../apis/series";

const DetailSeries = () => {
  const [data, setData] = useState({
    id: null,
    title: "loading",
    original_path: "",
    description: "",
    script: "",
    min_duration: "",
    max_duration: "",
    progress: 0,
    progress_text: "Loading..."
  });
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  const dataLoadHandler = () => {
    getLatestSeries(id).then((resp) => {
      if (resp.status === 200) {
        resp.json().then((respData) => {
          setData(respData);
          if (respData.progress === 100) {
            setLoaded(true);
          }
        });
      } else {
        // Handle error or redirection
      }
    });
  };

  useEffect(() => {
    dataLoadHandler();
    const intervalId = setInterval(() => {
      dataLoadHandler();
    }, 5000);

    // Stop the interval once the video is loaded
    if (loaded) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [id, loaded]);

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:h-screen gap-8 p-4 lg:p-0">
      {/* First Block: Video with Placeholder */}
      <div className="flex flex-col items-center w-full lg:w-[25vw]">
        <div className="w-full h-[56.25vw] lg:h-[44.44vw] bg-gray-200 flex justify-center items-center">
          {!loaded && (
            <div className="text-center">
              <span className="text-gray-500 mb-2 block">{data.progress_text}</span>
              <Progress percent={data.progress} />
            </div>
          )}
          {loaded && (
            <video className="w-full h-full" controls src={data.original_path}>
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        {loaded && (
          <Button
            type="primary"
            className="mt-4 text-white bg-purple-600"
          >
            <a href={data.original_path} download>
              Download Video
            </a>
          </Button>
        )}
      </div>

      {/* Second Block: Text Inputs with Ant Design */}
      <div className="flex flex-col w-full lg:w-[40vw]">
        <Form layout="vertical">
          <Form.Item label="Title">
            <Input placeholder="Title" value={data.title} />
          </Form.Item>
          <Form.Item label="Description">
            <Input placeholder="Description" value={data.description} />
          </Form.Item>
          <Form.Item label="Script">
            <Input.TextArea placeholder="Script" rows={8} value={data.script} />
          </Form.Item>
          <Form.Item>
            <Button
              disabled
              type="primary"
              className="text-white bg-purple-600"
            >
              <b>Save</b>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DetailSeries;
