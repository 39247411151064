import { useEffect, useState } from "react";
import ENDPOINTS from "../constants/endpoints";
import { listSeries } from "../apis/series";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";

const Views = props => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(()=>{
        listSeries().then((resp)=>{
            if (resp.status === 200){
                console.log(data)
                resp.json().then((jsonData)=>{
                    
                    console.log(jsonData)
                    setData(jsonData)
                })
                // console.log(value)
                
            }
        })
    }, [])
    return <>
    {data.map((item)=><Card title={item.content} onClick= {()=>{
            console.log("clikcing")
            navigate(`/series/detail/${item.id}`)
        }}>
       
        <p>Duration: {item.min_duration}-{item.max_duration} secs</p>
        <b><p>Videos: {item.videos_produced}</p></b>
    </Card>)}
    </>
}

export default Views;