import React, { useEffect } from "react";
import { Layout } from "antd";
import LandingPageFront from "../components/LandingPageFront";
import PricingTableLandingPage from "../components/PricingTableLandingPage";
import Accordion from "../components/Accordion";
import Footer from "../components/Footer";
import step1Image from "../media/step1.png";
import step2Image from "../media/step2.png";
import step3Image from "../media/step3.png";
import VideoCarousel from "../components/VideoExamples";
import ReactGA from "react-ga4";

const { Content } = Layout;

const LandingPage = () => {
  useEffect(() => {
    // Initialize Google Analytics

    // Track page view
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Content style={{ textAlign: "center", width: "100%" }}>
          <LandingPageFront style={{ minHeight: "90%" }} />
          <section>
            <div className="px-4 md:px-12">
              <VideoCarousel />
            </div>
            <div className="content-center mt-12 px-4 md:px-12" id="pricing">
              <PricingTableLandingPage />
            </div>
          </section>
          <div className="mt-12 px-4 md:px-12">
            <p className="text-5xl font-extrabold text-center text-gray-900 mb-6">How does it work?</p>
            <p className="text-4xl font-extrabold text-center text-gray-900">Faceless channels on auto-pilot</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div className="flex flex-col items-center justify-center text-center">
                <p className="text-5xl mb-4 font-extrabold text-center text-gray-900">Step 1</p>
                <p className="text-2xl mb-4 font-extrabold text-center text-gray-900">Create a Series</p>
                <p className="text-xl px-4 mb-6">
                  Choose a topic for your faceless video series. Select from our preset list. Our AI will begin
                  crafting your first unique video immediately.
                </p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={step1Image}
                  alt="Step 1 Image"
                  className="border-4 border-transparent rounded-lg p-4"
                  style={{
                    borderImage: "linear-gradient(to right, #7F00FF, #E100FF) 1",
                    maxWidth: "100%",
                  }}
                />
              </div>

              <div className="flex justify-center items-center">
                <img
                  src={step2Image}
                  alt="Step 2 Image"
                  className="border-4 border-transparent rounded-lg p-4"
                  style={{
                    borderImage: "linear-gradient(to right, #7F00FF, #E100FF) 1",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <p className="text-5xl mb-4 font-extrabold text-center text-gray-900">Step 2</p>
                <p className="text-2xl mb-4 font-extrabold text-center text-gray-900">Preview and Customize</p>
                <p className="text-xl px-4 mb-6">
                  Review your AI-generated video before it's posted. Edit the script, title, or background music as needed.
                  Each video is uniquely created for your series.
                </p>
              </div>

              <div className="flex flex-col items-center justify-center text-center">
                <p className="text-5xl mb-4 font-extrabold text-center text-gray-900">Step 3</p>
                <p className="text-2xl mb-4 font-extrabold text-center text-gray-900">Automate and Manage</p>
                <p className="text-xl px-4 mb-6">
                  Edit your posting schedule, connect your channels, and let Youshorts.ai handle the rest.
                </p>
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={step3Image}
                  alt="Step 3 Image"
                  className="border-4 border-transparent rounded-lg p-4"
                  style={{
                    borderImage: "linear-gradient(to right, #7F00FF, #E100FF) 1",
                    maxWidth: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </Content>
      </div>
      <Accordion id="faqs" />
      <Footer />
    </Layout>
  );
};

export default LandingPage;
