const BillingCancel = () => {
    
    return (
        <div>
            <h1>Cancel</h1>
            <a>Home go to page</a>
        </div>
    )
}

export default BillingCancel;