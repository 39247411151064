import { Button, Input, message } from "antd";
import { useEffect, useState } from "react";
import EmailComponent from "../components/settings/EmailComponent";
import PasswordComponent from "../components/settings/PasswordComponent";

const Settings = (props) => {
    return (
        <div className="p-6 space-y-8">
            <h1 className="text-2xl font-bold mb-4">Settings</h1>
            <div>
                <h2 className="text-xl font-semibold mb-2">Email</h2>
                <p className="text-gray-700 mb-4">
                    Set or update the email address where you will receive notifications
                    about your account and posts.
                </p>
            </div>
            
            <EmailComponent />
            <PasswordComponent />
        </div>
    );
};

export default Settings;
