import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold mb-6 text-purple-700">Privacy Policy</h1>
        
        <p className="mb-4">
          At Geneshorts.ai, we prioritize the privacy of our users. This Privacy Policy outlines how we collect, use, share, and protect your personal information, including Google user data. It also explains your rights concerning your data.
        </p>

        <p className="mb-4">
          GeneShorts.ai operates the website <a href="https://geneshorts.ai" className="text-purple-700 underline">https://geneshorts.ai</a> (referred to as the "Service").
        </p>

        <p className="mb-4">
          This document is intended to inform website visitors and users of our Service about our policies regarding the collection, use, and disclosure of Personal Information and Google user data. By using our Service, you consent to the practices described in this Privacy Policy.
        </p>

        <p className="mb-4">
          The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at <a href="https://geneshorts.ai" className="text-purple-700 underline">https://geneshorts.ai</a>, unless otherwise defined here.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Information Collection and Use</h2>

        <p className="mb-4">
          To enhance your experience while using our Service, we may require you to provide certain personally identifiable information, including but not limited to your name, phone number, and postal address. If our Service integrates with Google services, we may also collect Google user data as authorized by you through Google's consent process. This information will be used to contact or identify you and to provide and improve the Service.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Third-Party Service Providers</h2>

        <p className="mb-4">
          Our Service may include links to the privacy policies of third-party service providers, such as:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><a href="https://www.youtube.com/t/terms" className="text-purple-700 underline">YouTube Terms of Service</a></li>
          <li><a href="https://policies.google.com/privacy" className="text-purple-700 underline">Google Privacy Policy</a></li>
          <li><a href="https://developers.google.com/terms" className="text-purple-700 underline">Google Terms of Service</a></li>
          <li><a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-purple-700 underline">Google API Services User Data Policy</a></li>
          {/* <li><a href="https://www.tiktok.com/legal/terms-of-service" className="text-purple-700 underline">TikTok Terms of Service</a></li> */}
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Google API Limited Use Disclosure</h2>

        <p className="mb-4">
          GeneShorts.ai utilizes Google APIs for features such as Google account sign-in and content posting to YouTube. Our use and transfer of information received from Google APIs adhere to the Google API Services User Data Policy, including its Limited Use requirements.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">YouTube Integration</h2>

        <p className="mb-4">
          GeneShorts.ai enables users to upload rendered videos to YouTube via an in-app API. By using this feature, you agree to be bound by the YouTube Terms of Service and the Google Privacy Policy. Users can revoke GeneShorts.ai’s access to their data at any time via Google’s security settings page.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Data Storage and Security</h2>

        <p className="mb-4">
          Google user data is securely stored on our servers, protected by industry-standard encryption and security practices. We retain this data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required by law.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Sharing of Google User Data</h2>

        <p className="mb-4">
          We do not share Google user data with third parties unless it is essential to provide the Service, comply with legal obligations, or protect our rights. When third parties process Google user data on our behalf, they do so under strict confidentiality agreements and in compliance with our Privacy Policy and applicable data protection laws.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Log Data and Cookies</h2>

        <p className="mb-4">
          Whenever you visit our Service, we may collect information that your browser sends, known as Log Data. This may include details such as your computer’s IP address, browser type, pages visited, and the time spent on those pages. We use cookies to enhance your user experience, as detailed in our Cookies Policy.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Service Providers</h2>

        <p className="mb-4">
          We may engage third-party companies and individuals to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Facilitate our Service;</li>
          <li>Provide the Service on our behalf;</li>
          <li>Assist us in analyzing how our Service is used.</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Security</h2>

        <p className="mb-4">
          We value your trust in providing us with your Personal Information and strive to use commercially acceptable means to protect it. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee its absolute security.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Links to Other Sites</h2>

        <p className="mb-4">
          Our Service may contain links to other websites. If you click on a third-party link, you will be directed to that site. We strongly recommend reviewing the Privacy Policy of any site you visit, as we have no control over and assume no responsibility for their content, privacy policies, or practices.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Children’s Privacy</h2>

        <p className="mb-4">
          Our Service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information from our servers. If you are a parent or guardian and believe your child has provided us with personal information, please contact us immediately.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Changes to This Privacy Policy</h2>

        <p className="mb-4">
          We may update our Privacy Policy from time to time. We advise you to review this page periodically for any changes. Any updates will be effective immediately upon posting on this page.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Contact Us</h2>

        <p>
          If you have any questions or suggestions regarding our Privacy Policy, please do not hesitate to contact us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
