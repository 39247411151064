
const domain = "http://localhost:8000"
const ENDPOINTS = {
    REGISTER_URL: `${domain}/users/register/`,
    LOGIN_URL: `${domain}/users/login/`,
    VERIFY_URL: `${domain}/users/verify/`,
    VERSION: `${domain}/account/version/`,
    BILLING_PLANS:`${domain}/billing/`,
    BILLING_LINK: (price_id, quantity, email)=>`${domain}/billing/plans/${price_id}/?quantity=${quantity}&email=${email}`,
    SERIES_LIST_CREATE: `${domain}/series/series/`,
    SERIES_GET_LATEST: (seriesId)=>`${domain}/series/series/latestvm/${seriesId}/`,
    ME_URL: `${domain}/users/me/`,
    UPDATED_PASSWORD_URL: `${domain}/users/password/`,
    CHANNELS_LIST_URL: `${domain}/channels/connected_accounts/`,
    CHANNELS_RUD_URL: (cID) => `${domain}/channels/connected_accounts/${cID}/`,
    CHANNELS_INIT_AUTH: (provider) => `${domain}/channels/init/${provider}/`,
}

export default ENDPOINTS;