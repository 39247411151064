import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const VideoCarousel = () => {
  const videos = [
    "http://localhost:8000/media/rendered/667_668_669_670_671_672_673_subtitled.mp4",
    "http://localhost:8000/media/rendered/661_662_663_664_665_666_subtitled.mp4",
    "http://localhost:8000/media/rendered/471_472_473_474_475_476_477_478_479subtitle.mp4",
    "http://localhost:8000/media/rendered/444_445_446_447_448_449_450_451_452_453_454subtitle.mp4",
    "http://localhost:8000/media/rendered/187_188_189_190_191_192_193_194_195_subtitled.mp4",
    "http://localhost:8000/media/rendered/358_359_360_361_362_363_subtitled.mp4",
    "http://localhost:8000/media/rendered/674_675_676_677_678_679_680_681_682_683_subtitled.mp4",
  ];

  return (
    <div className="w-full bg-gradient-to-r from-purple-500 to-indigo-500 py-8">
      <h2 className="text-white text-3xl font-bold text-center mb-4">
        AMAZING VIDEOS EACH TIME
      </h2>
      <p className="text-white text-lg text-center mb-8">
        You can choose any topic you like
      </p>

      <div className="container mx-auto px-4">
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          navigation
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, Autoplay]}
          autoplay={{
            delay: 0,  // No delay between transitions
            disableOnInteraction: false,
            waitForTransition: false, // Start next slide without waiting for the previous one to end
          }}
          speed={3000}  // Increase speed for a smoother continuous motion (adjust this value as needed)
          loop={true}  // Enables infinite looping
          className="mySwiper"
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index} className="bg-white rounded-lg overflow-hidden shadow-lg">
              <video
                src={video}
                playsInline
                autoPlay
                loop
                muted
                className="w-full h-auto object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default VideoCarousel;
