import { useEffect, useState } from "react";
import PlanCard from "./billing/PlanCard";
import { getBillingPlans } from "../apis/billing";

const PricingTableLandingPage = (props) => {
  const [billingPlans, setBillingPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBillingPlansData = async () => {
    try {
      const resp = await getBillingPlans(true);
      if (resp.status !== 200) {
        console.error("Failed to fetch billing plans");
      } else {
        const data = await resp.json();
        setBillingPlans(data);
      }
    } catch (error) {
      console.error("An error occurred while fetching billing plans:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBillingPlansData();
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-50 text-xl">Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 to-indigo-500 py-10 px-6">
      <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-5xl font-extrabold text-center text-gray-900 mb-12">Subscription Plans</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {billingPlans.length > 0 ? (
            billingPlans.map((data) => (
              <PlanCard key={data.plan_id} data={data} isLanding={true} />
            ))
          ) : (
            <p className="col-span-full text-center text-lg text-gray-600">No billing plans available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingTableLandingPage;
