import { Button, Input, message } from "antd";
import { getMeData, updateEmail } from "../../apis/auth";
import { useEffect, useState } from "react";
import GradientButton from "../GradientButton";

const EmailComponent = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleEmailHandler = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const validateEmail = (email) => {
    // Simple regex for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailUpdate = async () => {
    if (validateEmail(email)) {
      setError("");
    } else {
      setError("Invalid email format");
      return;
    }

    const resp = await updateEmail({ email });
    const data = await resp.json();
    const msg = data.msg;
    if (resp.status === 200) {
      messageApi.open({
        type: "success",
        content: msg,
      });
    } else {
      messageApi.open({
        type: "error",
        content: msg,
      });
    }
  };

  const fetchData = async () => {
    const resp = await getMeData();
    const data = await resp.json();
    if (resp.status === 200) {
      setEmail(data.email);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {contextHolder}

      <h3 className="text-lg font-semibold mb-4">Email address</h3>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="w-full max-w-lg">
        <Input
          className="mb-4"
          type="email"
          value={email}
          onChange={handleEmailHandler}
          placeholder="Enter your email"
        />
<GradientButton
  onClick={handleEmailUpdate}
  text="Update"
  gradientColors = {['#9F7AEA', '#6B46C1']} // Custom gradient colors
  bgAnimation="animate-pulse" // Optional animation class
  textColor="text-white" // Optional text color class
/>
      </div>
    </>
  );
};

export default EmailComponent;
