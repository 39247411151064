import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchVersionData } from "../apis/version";
import { versionActions } from "../store/version-slice";

const RootLayout = () => {
  const dispatch = useDispatch();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const dataLoader = async () => {
    const resp = await fetchVersionData();
    const data = await resp.json();
    dispatch(versionActions.setVersionReducer({ data }));
  };

  useEffect(() => {
    dataLoader();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-50">
      {/* Sidebar for large screens */}
      <aside
        className={`fixed top-0 left-0 h-full lg:w-64 w-64 bg-gradient-to-b from-purple-500 to-purple-700 shadow-lg z-20 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 transition-transform duration-300 ease-in-out`}
      >
        <div className="py-6 px-6 text-white text-2xl font-bold tracking-wide">
          You Shorts
        </div>
        <nav className="mt-6">
          <ul className="space-y-2">
            <li>
              <Link
                to="/create"
                className="block py-3 px-6 text-white hover:bg-purple-600 rounded transition-colors duration-300"
              >
                Create
              </Link>
            </li>
            <li>
              <Link
                to="/views"
                className="block py-3 px-6 text-white hover:bg-purple-600 rounded transition-colors duration-300"
              >
                Series
              </Link>
            </li>
            <li>
              <Link
                to="/channels"
                className="block py-3 px-6 text-white hover:bg-purple-600 rounded transition-colors duration-300"
              >
                Channels
              </Link>
            </li>
            <li>
              <Link
                to="/billing"
                className="block py-3 px-6 text-white hover:bg-purple-600 rounded transition-colors duration-300"
              >
                Billing
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className="block py-3 px-6 text-white hover:bg-purple-600 rounded transition-colors duration-300"
              >
                Settings
              </Link>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Toggle Button for Mobile */}
      <div className="lg:hidden flex items-center justify-between bg-purple-500 text-white py-4 px-6">
        <button
          onClick={() => setSidebarOpen(!isSidebarOpen)}
          className="focus:outline-none text-white"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div className="text-xl font-bold">You Shorts</div>
      </div>

      {/* Main Content Area */}
      <div className={`flex-1 lg:ml-64 transition-all duration-300 ease-in-out`}>
        {/* Top Navigation Bar */}
        <header className="bg-white shadow-md py-4 px-6 lg:px-8 flex justify-end items-center space-x-4">
          <Link
            to="/billing"
            className="relative px-4 py-2 text-white bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-lg shadow-md hover:from-yellow-500 hover:to-yellow-700 transition-colors duration-300"
          >
            Upgrade
            <div className="absolute inset-0 rounded-lg"></div>
          </Link>

          <Link
            to="/logout"
            className="px-4 py-2 text-white bg-gradient-to-r from-purple-500 to-purple-700 rounded-lg shadow-md transition-colors duration-0"
          >
            Logout
          </Link>
        </header>

        {/* Main Content */}
        <main className="p-4 lg:p-6">
          <Outlet />
        </main>
      </div>

      {/* Backdrop for Mobile Sidebar */}
      {isSidebarOpen && (
        <div
          onClick={() => setSidebarOpen(false)}
          className="fixed inset-0 bg-black opacity-50 z-10 lg:hidden"
        ></div>
      )}
    </div>
  );
};

export default RootLayout;
