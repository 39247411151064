import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold mb-6 text-purple-700">Terms & Conditions</h1>
        
        <p className="mb-4">
          Welcome to AutoShorts.ai. These Terms and Conditions govern your use of our website, located at <a href="https://autoshorts.ai" className="text-purple-700 underline">https://autoshorts.ai</a>.
        </p>

        <p className="mb-4">
          By accessing or using this website, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using AutoShorts.ai.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Intellectual Property Rights</h2>

        <p className="mb-4">
          Images uploaded to AutoShorts.ai are not stored in our database nor made accessible to other users. The copyright for all images submitted to AutoShorts.ai remains with the original owner or author.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">External Links and Third-Party Content</h2>

        <p className="mb-4">
          AutoShorts.ai may provide search results that link to third-party websites not owned or controlled by us. We do not assume any responsibility for the content, privacy policies, or practices of any third-party websites.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Use of YouTube API Services</h2>

        <p className="mb-4">
          AutoShorts.ai integrates with YouTube API Services. By using our services, you agree to be bound by the <a href="https://www.youtube.com/t/terms" className="text-purple-700 underline">YouTube Terms of Service</a> and the <a href="https://policies.google.com/privacy" className="text-purple-700 underline">Google Privacy Policy</a>.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Disclaimer of Warranties</h2>

        <p className="mb-4">
          AutoShorts.ai is provided "as is" and without any warranties, whether express or implied. We do not represent or warrant that AutoShorts.ai will be free of inaccuracies, errors, bugs, or interruptions, or that it will be reliable, accurate, complete, or otherwise valid.
        </p>

        <p className="mb-4">
          We expressly disclaim all warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, availability, security, title, and non-infringement.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Limitation of Liability</h2>

        <p className="mb-4">
          Under no circumstances shall AutoShorts.ai be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of the website, whether based on breach of contract, breach of warranty, tort (including negligence), product liability, or otherwise. This includes, but is not limited to, any damage to your computer system or loss of data.
        </p>

        <p className="mb-4">
          Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. In such jurisdictions, the limitations set forth herein may not fully apply to you, and you may have additional rights.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Use of Cookies</h2>

        <p className="mb-4">
          We use cookies to enhance your experience on AutoShorts.ai. By accessing our website, you consent to the use of cookies in accordance with our Privacy Policy.
        </p>

        <p className="mb-4">
          Cookies are utilized to enable certain functionalities on our website, such as retaining user preferences and improving the overall user experience. Some of our affiliate or advertising partners may also use cookies.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Logo and Artwork Usage</h2>

        <p className="mb-4">
          The use of AutoShorts.ai’s logo or other artwork for linking purposes is strictly prohibited without a trademark license agreement.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">iFrames</h2>

        <p className="mb-4">
          Without prior written approval, you may not create frames around our webpages that alter the visual presentation or appearance of our website.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Content Liability</h2>

        <p className="mb-4">
          We are not responsible for any content that appears in videos created using AutoShorts.ai or on the websites, channels, or accounts where such videos are used. You agree to indemnify and defend us against all claims related to the content you produce or distribute.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Reservation of Rights</h2>

        <p className="mb-4">
          We reserve the right to request the removal of any link to our website. Upon such a request, you agree to promptly remove the link. We also reserve the right to amend these Terms and Conditions and our linking policy at any time. By continuing to link to our website, you agree to be bound by these Terms and Conditions.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Removal of Links from Our Website</h2>

        <p className="mb-4">
          If you find any link on our website that you consider offensive or inappropriate, please contact us. While we will consider requests to remove links, we are not obligated to do so or to respond directly.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Accuracy of Information</h2>

        <p className="mb-4">
          We do not warrant the completeness or accuracy of the information on our website. We do not guarantee that the website will remain available or that the material on the website will be kept up to date.
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-4 text-purple-700">Disclaimer</h2>

        <p className="mb-4">
          To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
        </p>

        <ul className="list-disc list-inside mb-4">
          <li>Limit or exclude our or your liability for death or personal injury;</li>
          <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
          <li>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
          <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
        </ul>

        <p className="mb-4">
          The limitations and exclusions of liability set forth in this section and elsewhere in this disclaimer are subject to the preceding paragraph and govern all liabilities arising under the disclaimer, including liabilities arising in contract, tort, and breach of statutory duty.
        </p>

        <p className="mb-4">
          As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
