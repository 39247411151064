import { Button, Card } from "antd";
import { useNavigate } from "react-router-dom";

const Series = (props) => {
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-semibold mb-4">Series</h1>
        <Button
          onClick={() => navigate('/create')}
          type="default"
          className="bg-orange-500 border-orange-500 text-white hover:bg-orange-600"
        >
          Create Series
        </Button>
      </div>
    </div>
  );
};

export default Series;
